import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import MenuEpicEvils from "./components/ui/MenuEpicEvils";
import Footer from "./components/ui/Footer";
import HomePage from "./pages/HomePage";
import ContactPage from "./pages/ContactPage";
import StandingsPage from "./pages/StandingsPage";
import TeamPage from "./pages/TeamPage";
import ClothesPage from "./pages/ClothesPage";
import CollabPage from "./pages/CollabPage";
import LoginPage from "./pages/LoginPage";
import WelcomeNonAlienPage from "./pages/WelcomeNonAlienPage";

import "./style.css";
import "./effects.css";

const App = () => {
  return (
    <Router>
      <div className="flex flex-col min-h-screen font-cygre">
        <MenuEpicEvils />
        <div className="py-8">
          <Routes>
            {/* Commercial */}
            <Route path="/" element={<HomePage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/standings" element={<StandingsPage />} />
            <Route path="/team" element={<TeamPage />} />
            <Route path="/collab" element={<CollabPage />} />
            <Route path="/clothes" element={<ClothesPage />} />
            {/* Auth */}
            <Route path="/login" element={<LoginPage />} />
            <Route
              path="/welcome-non-alien-resident"
              element={<WelcomeNonAlienPage />}
            />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
